import { faArrowLeft, faCheck, faScissors } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { setImage } from '../../store/actions/ImageActions'
import { GlobalContext } from '../../store/GlobalContextProvider'
interface Point {
    x: number
    y: number
}

export const Clipping = () => {

    const { imageState, imageDispatch } = React.useContext(GlobalContext)

    const canvasRef = React.useRef<HTMLCanvasElement>(null)
    const isMouseDown = React.useRef<boolean>(false)
    const startPoint = React.useRef<Point>({ x: 0, y: 0 })
    const endPoint = React.useRef<Point>({ x: 0, y: 0 })
    const ratio = React.useRef<number>(1)
    const maskCanvasRef = React.useRef<HTMLCanvasElement>(document.createElement("canvas"))
    const image = React.useRef<HTMLImageElement>(new Image())

    React.useEffect(() => {
        image.current.src = imageState.image ? imageState.image : ""
        if (canvasRef.current) {
            ratio.current = canvasRef.current.clientWidth / image.current.width
            canvasRef.current.width = canvasRef.current.clientWidth
            canvasRef.current.height = image.current.height * ratio.current

            maskCanvasRef.current.width = canvasRef.current.width
            maskCanvasRef.current.height = canvasRef.current.height
        }
    }, [])
    const draw = (ctx: CanvasRenderingContext2D, maskCtx: CanvasRenderingContext2D) => {

        if (!canvasRef || !canvasRef.current) {
            console.log("error referenci")
            return
        }

        ctx.drawImage(image.current, 0, 0, image.current.width, image.current.height, 0, 0, canvasRef.current.width, canvasRef.current.height)

        if (maskCtx) {
            maskCtx.fillStyle = "rgba(255,0,0,1)"
            maskCtx.clearRect(0, 0, maskCanvasRef.current.width, maskCanvasRef.current.height)
            maskCtx.fillStyle = "rgba(0,0,0,.5)"
            maskCtx.fillRect(0, 0, maskCanvasRef.current.width, maskCanvasRef.current.height)
            maskCtx.globalCompositeOperation = "destination-out"
            maskCtx.fillRect(startPoint.current.x, startPoint.current.y, endPoint.current.x - startPoint.current.x, endPoint.current.y - startPoint.current.y)
            ctx.drawImage(maskCtx.canvas, 0, 0)
            maskCtx.globalCompositeOperation = "source-over"
        }

        ctx.strokeStyle = "#ffffff"
        ctx.strokeRect(startPoint.current.x, startPoint.current.y, endPoint.current.x - startPoint.current.x, endPoint.current.y - startPoint.current.y)

        ctx.strokeRect(startPoint.current.x - 4, startPoint.current.y - 4, 8, 8);

        ctx.strokeRect(endPoint.current.x - 4, endPoint.current.y - 4, 8, 8);

        ctx.strokeRect(startPoint.current.x - 4, endPoint.current.y - 4, 8, 8);

        ctx.strokeRect(endPoint.current.x - 4, startPoint.current.y - 4, 8, 8);

    }


    React.useEffect(() => {

        if (!canvasRef || !canvasRef.current) {
            console.log("Problem")
            return
        }

        let ctx = canvasRef.current.getContext("2d")
        let maskCtx = maskCanvasRef.current.getContext("2d")

        if (!ctx || !maskCtx || ctx === null || maskCtx === null) {
            console.log("Error context")
            return
        }
        canvasRef.current.onmousedown = (e) => {
            isMouseDown.current = true
            startPoint.current = { x: e.offsetX, y: e.offsetY }
            endPoint.current = { x: e.offsetX, y: e.offsetY }
            draw(ctx!, maskCtx!)
        }

        canvasRef.current.onmousemove = (e) => {
            if (isMouseDown.current) {
                endPoint.current = { x: e.offsetX, y: e.offsetY }
                draw(ctx!, maskCtx!)
            }
        }
        canvasRef.current.onmouseup = (e) => {
            isMouseDown.current = false
            endPoint.current = { x: e.offsetX, y: e.offsetY }
            draw(ctx!, maskCtx!)
        }
        canvasRef.current.ontouchstart = (e) => {
            if (!e || e.target === null) {
                return
            }
            e.preventDefault()

            let x: number = e.targetTouches[0].pageX - canvasRef.current?.offsetLeft!
            let y: number = e.targetTouches[0].pageY - canvasRef.current?.offsetTop!
            startPoint.current = { x: x, y: y }
            endPoint.current = { x: x, y: y }
            draw(ctx!, maskCtx!)
        }
        canvasRef.current.ontouchmove = (e) => {
            if (!e || e.target === null) {
                return
            }
            e.preventDefault()
            let x: number = e.targetTouches[0].pageX - canvasRef.current?.offsetLeft!
            let y: number = e.targetTouches[0].pageY - canvasRef.current?.offsetTop!
            endPoint.current = { x: x, y: y }
            draw(ctx!, maskCtx!)
        }

        draw(ctx, maskCtx)

    }, [])

    return (<Container fluid className='p-0'>
        <h2 className='ms-3'>
            <FontAwesomeIcon icon={faScissors} className="me-1" />
            Clipping
        </h2>
        {/* <hr /> */}
        {!imageState.image ? <div className='d-flex justify-content-center'> <ClipLoader loading={true} /></div>
            : <div className='border rounded p-3'>
                <div className="d-flex justify-content-around">
                    <Link to="/preview">
                        <Button variant="outline-dark"> <FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                    </Link>
                    <Link to="/preview">
                        <Button variant="outline-success" onClick={() => {

                            if (startPoint.current.x - endPoint.current.x === 0 ||
                                startPoint.current.y - endPoint.current.y === 0) {
                                return
                            }

                            let fullCanvas = document.createElement('canvas')
                            fullCanvas.width = image.current.width
                            fullCanvas.height = image.current.height
                            let fullCtx = fullCanvas.getContext('2d')
                            fullCtx?.drawImage(image.current, 0, 0)
                            if (!canvasRef.current) {
                                console.log("Error")
                                return
                            }

                            let currentStart: Point = { x: (startPoint.current.x / canvasRef.current?.width) * image.current.width, y: (startPoint.current.y / canvasRef.current?.height) * image.current.height }
                            let currentEnd: Point = { x: (endPoint.current.x / canvasRef.current?.width) * image.current.width, y: (endPoint.current.y / canvasRef.current?.height) * image.current.height }



                            let imgData = fullCtx?.getImageData(currentStart.x, currentStart.y, currentEnd.x - currentStart.x, currentEnd.y - currentStart.y)
                            if (!imgData) {
                                return
                            }

                            let resultCanvas = document.createElement("canvas")
                            resultCanvas.width = Math.abs(currentStart.x - currentEnd.x)
                            resultCanvas.height = Math.abs(currentStart.y - currentEnd.y)
                            let resultCtx = resultCanvas.getContext("2d")

                            resultCtx?.putImageData(imgData, 0, 0)

                            let url = resultCanvas.toDataURL()
                            setImage(imageDispatch, url)

                            // setImageURI(imageDispatch, )
                        }}> <FontAwesomeIcon icon={faCheck} /> Ok</Button>
                    </Link>

                </div>
                <div className="d-flex justify-content-center mt-1">
                    <canvas className="responsive-canvas" ref={canvasRef}></canvas>
                </div>
            </div>}
    </Container>)
}