import React from 'react'
import { IImageAction, IImageState, imageInitialState, imageReducer } from './reducers/ImageReducer'
import { INotificationsAction, INotificationsState, notificationsInitialState, notificationsReducer } from './reducers/NotificationsReducer'

interface IGlobalContext {
    imageState: IImageState
    imageDispatch: (action: IImageAction) => void

    notificationsState: INotificationsState
    notificationsDispatch: (action: INotificationsAction) => void
}

export const GlobalContext = React.createContext<IGlobalContext>({
    imageState: imageInitialState,
    imageDispatch: () => { },
    notificationsState: notificationsInitialState,
    notificationsDispatch: () => {}
})

interface GlobalContextProviderProps {
    children: React.ReactNode
}


export const GlobalContextProvider = (props: GlobalContextProviderProps) => {

    const [imageState, imageDispatch] = React.useReducer(imageReducer, imageInitialState)
    const [notificationsState, notificationsDispatch] = React.useReducer(notificationsReducer, notificationsInitialState)

    const store = React.useMemo(() => ({
        imageState, imageDispatch,
        notificationsState, notificationsDispatch
    }), [imageState, notificationsState])


    return (
        <GlobalContext.Provider value={store}>
            {props.children}
        </GlobalContext.Provider>
    )
}