import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../store/GlobalContextProvider'

interface Props {
    children: React.ReactNode
}

export const CustomRoute = (props: Props) => {
    const navigate = useNavigate()
    const {imageState} = React.useContext(GlobalContext)

    React.useEffect(()=>{
        if(!imageState.image){
            navigate("/")
        }
    }, [])

    return (
        <>
            {props.children}
        </>
    )
}