import React from "react"
import { INotificationsAction } from "../reducers/NotificationsReducer"

export const ADD_NOTIFICATION: string = "ADD_NOTIFICATION"
export const REMOVE_NOTIFICATION: string = "REMOVE_NOTIFICATION"


export const addNotification = (dispatch: React.Dispatch<INotificationsAction>, title: string, text: string, variant: string, time?: number) => {


    const notification = {
        id: `notification_${Date.now()}`,
        title: title,
        text: text,
        variant: variant
    }

    if (time) {
        setInterval(() => {
            removeNotification(dispatch, notification.id)
        }, time)
    }

    dispatch({ type: ADD_NOTIFICATION, payload: { notification: notification } })
}

export const removeNotification = (dispatch: React.Dispatch<INotificationsAction>, id: string) => {
    dispatch({type: REMOVE_NOTIFICATION, payload:{id: id}})
}