import { faArrowLeft, faCircleCheck, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { resetImage, resetImageDetail } from '../../store/actions/ImageActions'
import { GlobalContext } from '../../store/GlobalContextProvider'
import { DebugPanel } from './components/DebugPane'

interface Props {
    isDebug?: boolean
}


export const Result = (props: Props) => {

    const { imageDispatch, imageState } = React.useContext(GlobalContext)

    return (
        <Container fluid className='p-0'>
            <div className="d-flex justify-content-between align-items-end">
                <h2 className='ms-2'>{<FontAwesomeIcon icon={faCircleCheck} className="me-1" />}Result</h2>
                <div className='me-2'>
                    <Link to="/preview" className='me-2'>
                        <Button variant='outline-dark'
                            onClick={() => { resetImageDetail(imageDispatch) }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className='me-1' />
                            Back
                        </Button>
                    </Link>
                    <Link to="/">
                        <Button variant='outline-dark'
                            onClick={() => { resetImage(imageDispatch) }}
                        >
                            <FontAwesomeIcon icon={faUpload} className="me-1" />
                            Back to upload
                        </Button>
                    </Link>
                </div>
            </div>
            {/* <hr /> */}
            {!imageState.detail ?
                <div className="d-flex justify-content-center">
                    <ClipLoader loading={true} />
                </div> : <>
                    <Row className='border rounded p-3 m-0 mt-3 align-items-center'>

                        <Col lg={true}>
                            <div className="d-flex justify-content-center mb-2">
                                <img src={imageState.image} width="75%" alt="" className='mx-auto' />
                            </div>
                            <div className="d-flex justify-content-center">
                                <img src={imageState.detail.imageURL} width="75%" alt="" />
                            </div>
                        </Col>
                        <Col className='font-monospace'>
                            <h4>Detail</h4>
                            <hr />
                            {props.isDebug && <>
                                <p className="mb-0 fw-bold">Location ID</p>
                                <p className="mb-1 ms-1">{imageState.detail.locationId}</p>
                            </>
                            }
                            <p className="mb-0 fw-bold">Distinct</p>
                            <p className="mb-1 ms-1">{imageState.detail.distinct}</p>
                            <p className="mb-0 fw-bold">Region</p>
                            <p className="mb-1 ms-1">{imageState.detail.region}</p>
                            <p className="mb-0 fw-bold">Location</p>
                            <p className="mb-1 ms-1">{imageState.detail.location}</p>
                            <p className="mb-0 fw-bold">Object</p>
                            <a target='_blank' rel='noopener noreferrer' href={imageState.detail.pageURL} className="mb-1 ms-1">
                                <Button variant='outline-dark' className="rounded-pill p-1 px-2">
                                    {imageState.detail.object}
                                </Button>
                            </a>
                        </Col>
                    </Row>
                    <Row className='px-0 mx-0'>
                        <Col className='px-0 mx-0'>
                            {props.isDebug && <DebugPanel />}
                        </Col>
                    </Row>
                </>
            }
        </Container>
    )
}