import { IImageDetail } from "../../types/ImageDetail"
import { RESET, SET_FILE, SET_IMAGE_DETAIL, SET_IMAGE, SET_ORIGIN, RESET_IMAGE_DETAIL } from "../actions/ImageActions"

export interface IImageState {
    image?: string
    origin?: string
    file?: File
    detail?: IImageDetail
    loadedImage: boolean
}
export interface IImageActionState{
    image?: string
    origin?: string
    file?: File
    detail?: IImageDetail

}
export interface IImageAction {
        type: string
        payload: IImageActionState
}
export const imageInitialState: IImageState = {
    image: undefined,
    file: undefined,
    detail: undefined,
    origin: undefined,
    loadedImage: false

}
export const imageReducer = (state: IImageState = imageInitialState, action: IImageAction): IImageState => {
    
    switch (action.type) {
        case SET_FILE:
            return {
                ...state,
                file: action.payload.file?action.payload.file:undefined
            }
        case SET_IMAGE:
            return{
                ...state,
                image: action.payload.image?action.payload.image:undefined,
                loadedImage: action.payload.image?true:false

            }
        case SET_IMAGE_DETAIL:
            return{
                ...state,
                detail: action.payload.detail?action.payload.detail:undefined
            }
        case SET_ORIGIN:
            return {
                ...state,
                image: action.payload.origin?action.payload.origin:undefined,
                origin: action.payload.origin?action.payload.origin:undefined,
                loadedImage: action.payload.origin?true:false

            }
        case RESET_IMAGE_DETAIL:
            return {...state, detail: undefined}
        case RESET:
            return imageInitialState 
        default: return state
    }
}