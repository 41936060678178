import { faCross } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { removeNotification } from '../store/actions/NotificationsActions'
import { GlobalContext } from '../store/GlobalContextProvider'
import { INotification } from '../store/reducers/NotificationsReducer'

interface Props {
    notification: INotification
}


export const Notification = (props: Props) => {

    const { notificationsDispatch } = React.useContext(GlobalContext)

    return (
        <>
            <Alert variant={`${props.notification.variant}`}
                className="cursor-pointer notification"
                onClick={() => {
                    removeNotification(notificationsDispatch, props.notification.id)
                }}
                dismissible
            >
                <Alert.Heading>
                    {props.notification.title}
                </Alert.Heading>
                {props.notification.text}
            </Alert>
        </>
    )
}