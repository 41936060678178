import React from 'react'
import { GlobalContext } from '../../../store/GlobalContextProvider'


export const DebugPanel = () => {

    const { imageState } = React.useContext(GlobalContext)

    return (
        <div>
            {
                imageState.detail && imageState.detail.detailData?.map((item, index) => {
                    return <div key={`detail_data${index}`} className="d-flex flex-column border p-2 mt-1">
                        <div>
                            <p> <span className='font-monospace'>Location: </span> <span className={`${item.locationId === imageState.detail?.locationId ? 'fw-bold' : 'fw-think'}`}>{item.locationId}</span></p>
                            <p> <span className='font-monospace'>Conformity: </span> {item.quality}</p>
                            <div className='d-flex justify-content-center align-items-center flex-column'>


                                <div key={`result_item_${index}`} className='d-flex'>
                                    <div className='me-2'>
                                        <p className='mb-0 mt-2'>From the uploaded image</p>
                                        <img src={item.yoloCrop} alt="yoloCrop" width="240" />
                                    </div>
                                    <div>
                                        <p className='mb-0 mt-2'>From dataset</p>
                                        <img src={item.datasetCrop} alt="datasetCrop" />
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    )
}