import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { setFile } from '../../store/actions/ImageActions'
import { addNotification } from '../../store/actions/NotificationsActions'
import { GlobalContext } from '../../store/GlobalContextProvider'

export const Upload = () => {

    let navigate = useNavigate()

    const { imageDispatch, imageState, notificationsDispatch } = React.useContext(GlobalContext)

    const validFileType = ["image/jpeg", "image/jpg", "image/png", "image/gif"]
    const [loading, setLoading] = React.useState<boolean>(false)

    const onDrop = React.useCallback((files: Array<File>) => {
        //save to local states
        if (!validFileType.includes(files[0].type)) {
            addNotification(notificationsDispatch, "Error", "Incorrect file type. Please use .jpg or .png", "danger")
            return;
        }
        setLoading(true)
        setFile(imageDispatch, notificationsDispatch, files[0])

    }, [])
    React.useEffect(() => {
        if (imageState.image) {
            navigate("/preview")
        }
    }, [imageState])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false })



    return (
        <Container fluid className='p-0'>
            <h2 className='ms-3'>{<FontAwesomeIcon icon={faUpload} className="me-1" />} Upload</h2>
            {loading ? <div className="d-flex justify-content-center">
                <ClipLoader loading={true} />
            </div> : <div className='border rounded'>
                <div {...getRootProps()} className={`m-3 p-3 dragAndDropPlace${isDragActive ? 'Enabled' : 'Disabled'}`} >

                    <input {...getInputProps()} />

                    <div className="d-flex justify-content-center">
                        <FontAwesomeIcon icon={faUpload} size="5x" />
                    </div>

                    <div className="d-flex justify-content-center">
                        <h4>
                            {isDragActive ? ("Drop file") : ("Upload file")}
                        </h4>
                    </div>

                </div>
            </div>
            }
        </Container>
    )
}