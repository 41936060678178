import React from "react"
import { IImageDetail } from "../../types/ImageDetail"
import { IImageAction } from "../reducers/ImageReducer"
import { INotificationsAction } from "../reducers/NotificationsReducer"
import { addNotification } from "./NotificationsActions"


export const SET_IMAGE: string = "SET_IMAGE"
export const SET_FILE: string = "SET_FILE"
export const RESET: string = "RESET"
export const RESET_IMAGE: string = "RESET_IMAGE"
export const SET_IMAGE_DETAIL: string = "SET_IMAGE_DETAIL"
export const SET_ORIGIN: string = "SET_ORIGIN"
export const RESET_IMAGE_DETAIL: string = "RESET_IMAGE_DETAIL"



export const resetImage = (dispatch: React.Dispatch<IImageAction>) => {
    dispatch({ type: RESET, payload: {} })
}

export const setImageDetail = (dispatch: React.Dispatch<IImageAction>, detail: IImageDetail) => {
    dispatch({ type: SET_IMAGE_DETAIL, payload: { detail: detail } })
}
export const setOrigin = (dispatch: React.Dispatch<IImageAction>, origin: string) => {
    dispatch({ type: SET_ORIGIN, payload: { origin: origin } })
}
export const setImage = (dispatch: React.Dispatch<IImageAction>, image: string) => {
    dispatch({ type: SET_IMAGE, payload: { image: image } })
}
export const resetImageDetail = (dispatch: React.Dispatch<IImageAction>) => {
    dispatch({type: RESET_IMAGE_DETAIL, payload: {}})
}
export const setFile = (dispatch: React.Dispatch<IImageAction>, 
    notificationsDispatch: React.Dispatch<INotificationsAction>, file: File) => {
    
        dispatch({ type: SET_FILE, payload: { file } })

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
        // setImageURI(`${e.target?.result}`)
        if (!e.target || !e.target.result) {
            addNotification(notificationsDispatch, "Error", "Error while reading file. Please try again", "danger")
            return
        }
        let uri = ""
        if (e.target.result instanceof ArrayBuffer) {
            uri = new TextDecoder("utf-8").decode(e.target.result)
        }
        else {
            uri = e.target.result
        }
        setOrigin(dispatch, uri)
    }

}